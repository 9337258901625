//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


	import { mapState } from 'vuex'
	import { mapMutations } from 'vuex'

	export default {
		scrollToTop: true,
		layout: 'default',
		transition: 'fade',
		head() {

			return this.head;
			
		},
		computed: {
			...mapState({
				head(state) {
					return state.page.content.head
				},
				blocks(state) {
					return state.page.content.blocks
				},
				children(state) {
					return state.page.children
				},
				hero(state) {
					return state.page.content.hero
				}
			})
		},
		async fetch({ store, route, app, error, router }) {

if (process.server)
{
	await app.$axios.$post('api/query', {
						query: "page('home')",

						select: {
							head: "page.title.toSeoHelper",
							breadcrumb: "page.title.toCrumbs",
							hero : "page.title.toHeroHelper",
							blocks: "page.blocks.toBlocksHelper",
						}
					}).then(resp =>
						{
							store.commit('page/setPageContent', resp.result);    		
						}
					).catch(function (error) {
							if (error.response) {
								// Request made and server responded
								console.log(error.response.data);
								console.log(error.response.status);
								console.log(error.response.headers);
							} else if (error.request) {
								// The request was made but no response was received
								console.log(error.request);
							} else {
								// Something happened in setting up the request that triggered an Error
								console.log('Error', error.message);
							}
					});
				

			}

		}
	}

